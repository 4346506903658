import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 分页获取资源问题清单
 * @param {Object} data
 */
export function getTroublePage (data) {
  return request({
    url: '/ooh-msp/v1/trouble/gettroublepage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 手动关闭上报任务
 * @param {Object} data
 */
export function closeTrouble (data) {
  return request({
    url: '/ooh-msp/v1/trouble/closetrouble',
    method: 'post',
    data: qs.stringify(data)
  })
}
