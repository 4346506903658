import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 设备故障上报记录
 * @param {Object} data
 */
export function getDeviceReportPage (data) {
  return request(
    {
      url: '/ooh-msp/v1/device/getdevicereportpage',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
/**
 * 获取设备基础信息
 * @param {Object} data
 */
export function getDeviceBasicinfo (data) {
  return request(
    {
      url: '/ooh-msp/v1/device/getdevicebasicinfo',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取已派单未完成巡检/维修/维护任务
 * @param {Object} data
 */
export function getAssignManitainTask (data) {
  return request(
    {
      url: '/ooh-msp/v1/device/getassignmanitaintask',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 运维计划统计
 * @param {Object} data
 */
export function getPlanStatisticalList (data) {
  return request(
    {
      url: '/ooh-msp/v1/device/getplanstatisticallist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 站点资源任务未完成点位
 * @param {Object} data
 */
export function getStationResourceList (data) {
  return request(
    {
      url: '/ooh-msp/v1/device/getstationresourcelist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 设备任务统计
 * @param {Object} data
 */
export function getTaskStatistical (data) {
  return request(
    {
      url: '/ooh-msp/v1/device/gettaskstatistical',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 分页获取设备运维任务列表
 * @param {Object} data
 */
export function getDeviceTaskitemPage (data) {
  return request(
    {
      url: '/ooh-msp/v1/device/getdevicetaskitempage',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 故障上报统计
 * @param {Object} data
 */
export function getTroubleReportStat (data) {
  return request(
    {
      url: '/ooh-msp/v1/device/gettroublereportstat',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取故障上报数据统计
 * @param {Object} data
 */
export function getTroubleReportList (data) {
  return request(
    {
      url: '/ooh-msp/v1/device/gettroublereportlist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 站点资源故障上报点位
 * @param {Object} data
 */
export function getStationTroubleList (data) {
  return request(
    {
      url: '/ooh-msp/v1/device/getstationtroublelist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取设备未完成故障任务列表
 * @param {Object} data
 */
export function getDeviceUndoneTroubleTaskitemList (data) {
  return request(
    {
      url: '/ooh-msp/v1/device/getdevicenotfinishtroubletaskitemlist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
