
import { getResourceById } from '@/api/product/productresource'
// import { getStatusQuantity } from '@/api/product/device'
import { getTroubleReportStat, getTroubleReportList } from '@/api/msp/device'
import { getStationPage } from '@/api/msp/demandV3'
import { getTroublePage } from '@/api/msp/trouble'
import { getPublishingTaskitemPage } from '@/api/msp/taskitem'
/** new api */
import { getStatusQuantity } from '@/api/rim/device'

const getDefaultState = () => {
  return {
    assetArray: [],
    mapStations: [],
    detailId: null,
    baseInfo: {},
    showTechParams: false,
    selectedResourceIdArray: [],
    selectedAssetId: null,
    selectedStationId: null,
    selectedDeviceTypeId: null,
    deviceStatusSta: [],
    deviceStatusArray: [],
    deviceReportTroubleData: [],
    taskData: {}, // 需要设置的任务数据
    tagType: 1,
    taskId: null,
    taskType: null,
    taskStatus: null,
    curCategory: null,
    showTab: '1',
    curDeviceRapairInfo: {}, // 当前设备故障信息
    undoneTroubleAmount: 0 // 指定设备未完成故障任务数量
  }
}
const state = getDefaultState()
const equipment = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_equipment_assetArray (state, data) {
      state.assetArray = data
    },
    set_equipment_mapStations (state, data) {
      state.mapStations = data
    },
    set_equipment_detailId (state, data) {
      state.detailId = data
    },
    set_equipment_baseInfo (state, data) {
      state.baseInfo = data
    },
    set_equipment_showTechParams (state, data) {
      state.showTechParams = data
    },
    set_selected_resourceId_array (state, data) {
      state.selectedResourceIdArray = data || []
    },
    set_selected_asset_id (state, data) {
      state.selectedAssetId = data
    },
    set_selected_station_id (state, data) {
      state.selectedStationId = data
    },
    set_selected_devicetype_id (state, data) {
      state.selectedDeviceTypeId = data
    },
    set_device_status_sta (state, data) {
      state.deviceStatusSta = data || []
    },
    set_device_status_array (state, data) {
      state.deviceStatusArray = data || []
    },
    set_device_report_trouble_data (state, data) {
      state.deviceReportTroubleData = data || []
    },
    set_task_data (state, data) {
      state.taskData = data
    },
    set_task_id (state, data) {
      state.taskId = data
    },
    set_task_type (state, data) {
      state.taskType = data
    },
    set_task_status (state, data) {
      state.taskStatus = data
    },
    set_tag_type (state, data) {
      state.tagType = data
    },
    set_cur_category (state, data) {
      state.curCategory = data
    },
    set_show_tab (state, data) {
      state.showTab = data
    },
    set_cur_device_rapair_info (state, data) {
      state.curDeviceRapairInfo = data
    },
    set_undone_trouble_amount (state, data) {
      state.undoneTroubleAmount = data
    }
  },
  getters: {

  },
  actions: {
    // 获取基础信息
    getBaseInfo ({ state, commit }, query) {
      return new Promise((resolve) => {
        getResourceById(query).then(res => {
          commit('set_equipment_baseInfo', res)
          resolve(res)
        })
      })
    },
    setShowTechParams ({ state, commit }, query) {
      return new Promise((resolve) => {
        getResourceById(query).then(res => {
          const showMoreParamsTypeIds = [301]
          commit('set_equipment_showTechParams', showMoreParamsTypeIds.includes(res.resourcetypeId))
          resolve(res)
        })
      })
    },
    getDeviceStatusStaData ({ state, commit }, query) {
      return new Promise((resolve) => {
        getStatusQuantity(query).then(res => {
          commit('set_device_status_sta', res)
          resolve(res)
        })
      })
    },
    getDeviceReportTroubleData ({ state, commit }, query) {
      return new Promise((resolve) => {
        getTroubleReportStat(query).then(res => {
          commit('set_device_report_trouble_data', res)
          resolve(res)
        })
      })
    },
    /**
     * 获取分页站点数据
     * @param {*} param0
     * @param {*} query
     * @returns
     */
    getStationData ({ state, commit }, query) {
      return new Promise((resolve) => {
        getStationPage(query).then(res => {
          commit('set_task_data', res)
          resolve(res)
        })
      })
    },
    /**
     * 获取上报数据
     * @param {*} param0
     * @param {*} query
     * @returns
     */
    getTroubleData ({ state, commit }, query) {
      return new Promise((resolve) => {
        getTroublePage(query).then(res => {
          commit('set_task_data', res)
          resolve(res)
        })
      })
    },
    /**
     * 获取已发布的任务数据
     * @param {*} param0
     * @param {*} query
     * @returns
     */
    getPublishingTaskData ({ state, commit }, query) {
      return new Promise((resolve) => {
        getPublishingTaskitemPage(query).then(res => {
          commit('set_task_data', res)
          resolve(res)
        })
      })
    },
    /**
     * 获取故障上报站点信息数据
     * @param {*} param0
     * @param {*} query
     * @returns
     */
    getTroubleReportStationData ({ commit }, query) {
      return new Promise((resolve) => {
        getTroubleReportList(query).then(res => {
          // commit('set_task_data', res)
          if (res && !res.errcode) {
            commit('set_equipment_mapStations', res.filter(x => x.totalCount))
          }
          resolve(res)
        })
      })
    }
  }
}

export default equipment
